import React from "react"
import Banner from "../components/Banner/banner"
import TextBlock from "../components/TextBlock/textBlock"
import TextBlockImg from "../components/TextBlockImg/textBlockImg"
import Perk from "../components/Perk/perk"
import Button from "../components/Button/button"
import Packages from "../components/Packages/packages"
import Package from "../components/Package/package"
import Contact from "../components/Contact/contact"
import { Link } from "react-scroll"

import perk1Img from "../images/Ryan_Headshot.jpg"
import perk2Img from "../images/Ryan-I_headshot.jpg"
// import perk3Img from "../images/friendly-staff.svg"

import { IconContext } from "react-icons"
// import { MdDone, MdClear } from "react-icons/md"

const HomePage = () => {
  return (
    <>
      <Banner />
      <TextBlock
        id="about"
        title="Precise & Professional Production for any size company, event, or organization"
        paragraph="The idea was born out necessity when in 2006 our founder was asked to join the production team as a volunteer of a non-profit association.  He quickly took the low quality and ineffective event production to a high quality, professional show with post-production sales of the lectures that helped elevate the groups status among and above other professional associations of similar type.  A few years later, he partnered with an old friend and our co-founder, who had just finished Film school and was working for Disney Resorts.  For the next 10 years, the two built the structure for what would become 10Blade Productions.  In 2020, we added the ability to also provide web application development to complete the ability to integrate live streaming into our event production services.  In early 2021, 10Blade Productions officially became an LLC.  As we grow, we have been adding to our services.  We are have the ability to build and maintain web applications, including mobile apps, as well as do equipment consultations and training for clients looking to add or upgrade their hybrid event offerings."
      >
        <Link to="team" smooth={true} duration={500}>
          <Button label="Our Team" cta="Meet our Team!" />
        </Link>
      </TextBlock>
      <TextBlockImg
        id="team"
        title="Our team might be small, but we are mighty!"
        subtitle="10Blade Productions was founded by two old friends with a passion for production excellence.  While our staff is small, we have years of experience and the ability to flex and grow with our clients needs."
      >
        <div className="flex-container trio-block">
          <Perk
            img={perk1Img}
            alt="Ryan Schniederjan Img"
            name="Ryan Schniederjan"
            title="CEO & Business Manager"
            skills="Skills & Background:"
            content="12 years in Live Event production,Full-Stack Developer,Producer,Post-production,Live and Post Audio,DIT,Technology Integration"
          />
          <Perk
            img={perk2Img}
            alt="Ryan Innis Img"
            name="Ryan Innis"
            title="Director of Productions, Director of Photography"
            skills="Skills & Background:"
            content="Graduate of Full Sail Film School,12+ years in Live Event Production,Camera Operator,Equipment Consulting & Installation"
          />
          {/* <Perk
            img={perk3Img}
            alt="Super fast speed increases"
            title="Friendly"
            content="Advisors who are available 24/7, all with exprt knowledge"
          /> */}
        </div>
      </TextBlockImg>
      <Packages
        title="Demo Reels, Projects, and Testimonials"
        para="Here is some of what we have done and what our clients say..."
      >
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/vHz0L3-aFVU"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>

        <IconContext.Provider
          value={{
            color: "#7FFF00",
            size: "1.2em",
            style: { verticalAlign: "middle", marginRight: "5px" },
          }}
        >
          {/* <Package title="Standard">
            <ul>
              <li>
                <MdDone />1 User
              </li>
              <li>
                <MdDone />
                1GB Storage
              </li>
              <li className="linethrough">
                <MdClear color="red" />
                Dedicated Advisor
              </li>
              <li className="linethrough">
                <MdClear color="red" />
                24/7 Support
              </li>
            </ul>
            <Link to="contact" smooth={true} duration={500}>
              <Button label="I want this" cta="I want this!" />
            </Link>
          </Package> */}
          {/* <Package title="Ryan Schniederjan Demo Reel" active={true}>
            <ul>
              <li>
                <MdDone />
                24/7 Support
              </li>
              <li>
                <MdDone />
                Dedicated Advisor
              </li>
              <li>
                <MdDone />
                Unlimited Storage
              </li>
              <li>
                <MdDone />
                Unlimited Users
              </li>
            </ul>
            <Link to="contact" smooth={true} duration={500}>
              <Button label="I want this" cta="I want this!" />
            </Link>
          </Package> */}
          {/* <Package title="Super">
            <ul>
              <li>
                <MdDone />
                10 Users
              </li>
              <li>
                <MdDone />
                500GB Storage
              </li>
              <li>
                <MdDone />
                Advice Support
              </li>
              <li className="linethrough">
                <MdClear color="red" />
                Dedicated Advisor
              </li>
            </ul>
            <Link to="contact" smooth={true} duration={500}>
              <Button label="I want this" cta="I want this!" />
            </Link>
          </Package> */}
        </IconContext.Provider>
      </Packages>
      <Contact
        id="contact"
        title="Contact 10Blade today and see how we can help your business"
        subtitle="No matter your size or your budget, we can find a solution for you."
      />
    </>
  )
}

export default HomePage
