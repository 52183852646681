export const mainMenuItems = [
  {
    path: "/",
    text: "about",
  },
  {
    path: "/",
    text: "demos",
  },
  {
    path: "/",
    text: "contact",
  },
]
