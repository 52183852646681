import React, { useEffect } from "react"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"
import { motion, useAnimation } from "framer-motion"
import { MdDone } from "react-icons/md"

const Perk = ({ img, alt, name, title, skills, content }) => {
  const controls = useAnimation()
  const [ref, inView] = useInView({
    // Percentage of item in view to trigger animation
    threshold: 0.25,
  })

  const skillsList = content.split(",")

  useEffect(() => {
    if (inView) {
      controls.start("visible")
    }
  }, [controls, inView])
  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: 25 },
      }}
      transition={{ ease: "easeOut", duration: 1.25, delay: 0.35 }}
    >
      <PerkWrapper className="perk">
        <img src={img} alt={alt} />
        <h2>{name}</h2>
        <h3>{title}</h3>
        <p>{skills}</p>
        <ul>
          {skillsList.map(skill => (
            // console.log("skill",skill)
            <li>
              <MdDone />
              <span>{skill}</span>
            </li>
          ))}
        </ul>
      </PerkWrapper>
    </motion.div>
  )
}

const PerkWrapper = styled.article`
  padding: 1rem;
  max-width: 350px;
  margin: 0 auto;

  img {
    box-sizing: border-box;
    width: 100%;

    @media (min-width: 992px) {
      padding: 0 1.75rem;
    }
  }

  h3 {
    font-weight: 400;
  }

  p {
    margin-bottom: 5px;
  }

  ul {
    list-style: none;
    padding-inline-start: 0px;
  }
  li {
    text-align: left;
  }
  span {
    padding-inline-start: 10px;
  }
`

export default Perk
